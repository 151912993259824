import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import ViewMenu from './ViewMenu'
import { hero, menu, mobileHero, viewMenu } from 'images'
import MenuModal from './MenuModal'

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) center/ cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView
            textCenter
            column
            center
            w="100%"
            ph="10%"
            pt="10px"
            bg="rgba(0, 0, 0, 0.70)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            {/* <CFText
              color="white"
              style={{
                fontSize: 28,
                color: '#BD9200',
              }}
              raleway
              xBold
            >
              10% OFF ONLINE ORDERS!
            </CFText> */}
            <CFView pulsate mv="5px">
              <OrderPickupButton />
            </CFView>
            <CFView
              hover
              onClick={() => setShowModal(true)}
              column
              center
              mb="15px"
            >
              <CFImage src={viewMenu} maxWidth="320px" alt="About" />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignStart
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            center
            w="100%"
            pv="3px"
            maxWidth="1400px"
            bg="rgba(0, 0, 0, 0.7)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            {/* <CFText
              color="white"
              style={{
                fontSize: 28,
                color: '#BD9200',
              }}
              raleway
              xBold
            >
              10% OFF ONLINE ORDERS!
            </CFText> */}
            <CFView pulsate mt="5px">
              <OrderPickupButton />
            </CFView>
            <CFView
              hover
              onClick={() => setShowModal(true)}
              column
              center
              ml="20px"
            >
              <CFImage src={viewMenu} w="100%" maxWidth="280px" alt="About" />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <MenuModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}
