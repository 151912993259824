import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import {
  CGI1,
  CGI2,
  CGI3,
  CGI4,
  CGI5,
  CGI6,
  CGI7,
  CGI8,
  CGI9,
  CGI10,
  wedding2,
  wedding3,
  wedding4,
  wedding5,
  wedding6,
  wedding7,
  wedding8,
  wedding9,
  wedding10,
  wedding11,
  wedding12,
  wedding13,
  wedding14,
  wedding15,
  wedding16,
  wedding17,
  wedding18,
  wedding19,
  about,
  aboutText,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={CGI1}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI2}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI3}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI4}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI5}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI6}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI7}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI8}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI9}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={CGI10}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <Slider {...{ ...settings, slidesToShow: 4 }}>
          <CFImage
            src={wedding2}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding3}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding4}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding5}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding6}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding7}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding8}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding9}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding10}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding11}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding12}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding13}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding14}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding15}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding16}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding17}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding18}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={wedding19}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
        </Slider>
        <CFView
          row
          justifyStart
          alignCenter
          pv="10%"
          pr="40px"
          h="520px"
          w="100%"
          maxWidth="1400px"
          image={`url(${about}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
        <Slider {...{ ...settings, slidesToShow: 3 }}>
          <CFImage
            src={CGI1}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI2}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI3}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI4}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI5}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI6}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI7}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI8}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI9}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
          <CFImage
            src={CGI10}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pv="15px"
          />
        </Slider>
      </DefaultScreen>
    </CFView>
  )
}
