import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { CFView, CFImage, PrimaryModal } from 'components'
import { promo } from 'images'
import { dispatch } from 'store'

export default () => {
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    const welcomeMessage = dispatch.restaurant.getWelcomeMessage()
    if (welcomeMessage.title) {
      setShowModal(true)
    }
  }, [])

  const closeModal = () => {
    setShowModal(false)
    // localStorage.setItem('welcomeSeen', true)
  }

  return (
    <PrimaryModal
      title="King Crab Special!"
      isOpen={showModal}
      onRequestClose={closeModal}
    >
      <CFView className={styles.container}>
        <CFView column w="100%">
          <CFView h3 black textCenter>
            {dispatch.restaurant.getWelcomeMessage().body}
          </CFView>
          <CFImage src={promo} w="100%" />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '640px',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  }),
}
