import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import {
  wedding,
  weddingButton,
  newYear,
  newYearButton,
  special,
  specialButton,
} from 'images'

export default props => {
  return (
    <PrimaryModal
      title={'Choose Menu'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container}>
        <CFView column alignCenter w="100%">
          <a target="_blank" href={wedding}>
            <CFView hover>
              <CFImage
                maxWidth="290px"
                src={weddingButton}
                alt="Skip the Dishes"
              />
            </CFView>
          </a>
          {/* <a target="_blank" href={newYear}>
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={newYearButton} alt="Door Dash" />
            </CFView>
          </a> */}
          <a target="_blank" href={special}>
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={specialButton} alt="UberEats" />
            </CFView>
          </a>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
