export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1699296377/grandcrystal/viewMenu5.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1591377852/general/cflogo_black.png'
export const aboutText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589305503/amigopancake/aboutText.png'
export const headerBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589305503/amigopancake/headerBg.jpg'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1659732918/grandcrystal/about2.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649183118/grandcrystal/footer_5_1.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1659732541/grandcrystal/foodGallery2.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649723499/grandcrystal/hero.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1631230035/jlsushi/heroText3.png'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1600214715/jlsushi/homebg.jpg'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649957374/grandcrystal/map.jpg'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649957374/grandcrystal/logo.png'
export const logoTitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1600214715/jlsushi/logoTitle.png'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649701921/grandcrystal/mobileAbout.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649702280/grandcrystal/mobileContactBg.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649703023/grandcrystal/mobileGallery1.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649724244/grandcrystal/mobileHero2.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1631230035/jlsushi/mobileHeroText3.png'
export const mobileLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649957374/grandcrystal/mobileLogo.png'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649957374/grandcrystal/mobileMap.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649702723/grandcrystal/mobilePromo.jpg'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1600214715/jlsushi/orderDeliveryButton.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1699296377/grandcrystal/orderPickupButton5.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1649704364/grandcrystal/promotions.jpg'
export const menu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647887062/naosushi/NaoMenu.pdf'
export const mobileGallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1659732541/grandcrystal/mobileGallery22.jpg'
export const CGI1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695405893/grandcrystal/GCI1.jpg'
export const CGI2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695407010/grandcrystal/GCI2.jpg'
export const CGI3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695405893/grandcrystal/GCI3.jpg'
export const CGI4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695406919/grandcrystal/GCI4.jpg'
export const CGI5 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695405893/grandcrystal/GCI5.jpg'
export const CGI6 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695405893/grandcrystal/GCI6.jpg'
export const CGI7 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695405893/grandcrystal/GCI7.jpg'
export const CGI8 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695406770/grandcrystal/CGI8.jpg'
export const CGI9 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695405893/grandcrystal/GCI9.jpg'
export const CGI10 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695405894/grandcrystal/GCI10.jpg'
export const wedding2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296779/grandcrystal/wedding2.jpg'
export const wedding3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296779/grandcrystal/wedding3.jpg'
export const wedding4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296780/grandcrystal/wedding4.jpg'
export const wedding5 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296780/grandcrystal/wedding5.jpg'
export const wedding6 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296780/grandcrystal/wedding6.jpg'
export const wedding7 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296781/grandcrystal/wedding7.jpg'
export const wedding8 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296782/grandcrystal/wedding8.jpg'
export const wedding9 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296782/grandcrystal/wedding9.jpg'
export const wedding10 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296783/grandcrystal/wedding10.jpg'
export const wedding11 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296783/grandcrystal/wedding11.jpg'
export const wedding12 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296784/grandcrystal/wedding12.jpg'
export const wedding13 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296785/grandcrystal/wedding13.jpg'
export const wedding14 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296785/grandcrystal/wedding14.jpg'
export const wedding15 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296785/grandcrystal/wedding15.jpg'
export const wedding16 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296786/grandcrystal/wedding16.jpg'
export const wedding17 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296786/grandcrystal/wedding17.jpg'
export const wedding18 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296788/grandcrystal/wedding18.jpg'
export const wedding19 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1699296788/grandcrystal/wedding19.jpg'
export const weddingButton =
  'https://db1lbxirnwwmr.cloudfront.net/weddingButton.webp'
export const newYearButton =
  'https://db1lbxirnwwmr.cloudfront.net/newYearButton.webp'
export const specialButton =
  'https://db1lbxirnwwmr.cloudfront.net/specialButton.webp'

export { default as promo } from './promo2.jpg'
export { default as wedding } from './wedding.pdf'
export { default as newYear } from './newYear.pdf'
export { default as special } from './special.pdf'
