import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  about,
  foodGallery,
  mobileGallery,
  mobileGallery2,
  wedding2,
  wedding3,
  wedding4,
  wedding5,
  wedding6,
  wedding7,
  wedding8,
  wedding9,
  wedding10,
  wedding11,
  wedding12,
  wedding13,
  wedding14,
  wedding15,
  wedding16,
  wedding17,
  wedding18,
  wedding19,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFImage src={mobileGallery} w="100%" alt="Food Gallery" />
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={wedding2}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding3}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding4}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding5}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding6}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding7}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding8}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding9}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding10}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding11}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding12}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding13}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding14}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding15}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding16}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding17}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding18}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={wedding19}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
        </Slider>
        <CFImage src={mobileGallery2} w="100%" alt="Food Gallery" />
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto">
          <CFImage src={foodGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
